import { Tag, Col, Icon, Cell, CellGroup, Toast, Tabbar, TabbarItem, NavBar } from "vant";
import { ref } from "vue";
import { allList } from "@/api/services.js";
import { useRouter, useRoute } from "vue-router";
export default {
  setup() {
    let router = useRouter();
    const activeTab = ref(0);
    const phone = ref(router.currentRoute.value.query.phone);
    const pageTitle = ref("核销记录-" + phone.value);
    return {
      activeTab,
      pageTitle,
      phone
    };
  },

  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [NavBar.name]: NavBar,
    Toast
  },

  data() {
    return {
      myOrderList: [],
      allList,
      tabsTitle: ["全部", "已核销", "已取消", "已过期"]
    };
  },

  created() {
    this.getAllList("");
  },

  methods: {
    //切换tab查询
    changeQuery(name, title) {
      console.log("change query");
      console.log(name);
      console.log(title);

      switch (title) {
        case "全部":
          this.getAllList();
          break;

        case "已核销":
          this.getAllList("200");
          break;

        case "已取消":
          this.getAllList("-1");
          break;

        case "已过期":
          this.getAllList("500");
          break;
      }
    },

    getAllList(status) {
      var obj = {
        phone: this.phone,
        status: status
      };
      this.allList(obj).then(res => {
        console.log(this.phone);
        console.log(res.data);

        if (res.data.code == 0) {
          //接收后台返回的数据，动态绑定到界面
          this.myOrderList = res.data.data;
        } else {
          this.$message.error("无法获取信息，请稍候重试");
        }
      }, error => {
        console.log(error);
      });
      return this.myOrderList;
    },

    onClickLeft() {
      this.$router.push({
        path: "./check",
        query: {
          phone: this.phone
        }
      });
    }

  }
};